import axios from "axios";

import router from "../router";

import { Message } from "element-ui";

export function getAxios(baseURL) {
    let infor = {};
    try {
        infor = JSON.parse(localStorage.getItem("infor"));
    } catch (error) {}
    const axiosInstance = axios.create({
        baseURL,
        headers: {
            Authorization: "Bearer " + infor?.token,
        },
    });
    axiosInstance.interceptors.response.use(
        (response) => {
            if (response.data.status === 200) {
                return response.data.data;
            }
            if (response.data.status === 401) {
                Message({
                    message: "TOKEN过期，请重新登录",
                    type: "error",
                    duration: 5 * 1000,
                    showClose: true,
                });
                return router.replace("/login");
            }

            if (!response.config?.hiddenErrorMsg) {
                Message({
                    message: response.data.message,
                    type: "error",
                    duration: 5 * 1000,
                    showClose: true,
                });
            }

            return Promise.reject(new Error(response.data.message));
            // return response;
        },
        (error) => {
            // token过期，重新登录
            if (error.response && error.response.status === 401) {
                Message({
                    message: "TOKEN过期，请重新登录",
                    type: "error",
                    duration: 5 * 1000,
                    showClose: true,
                });
                router.replace("/login");
            }
        }
    );

    return axiosInstance;
}
