import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const state = {
  infor: {},
  userInfo: {},
};

const mutations = {
  saveInfor(state, infor) {
    state.infor = infor;
  },
  updateUserInfo(state, userInfo) {
    state.userInfo = userInfo;
  }
};

export default new Vuex.Store({
  state,
  mutations,
});
