<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data() {
    const item = {
      date: "2016-05-02",
      name: "王小虎",
      address: "上海市普陀区金沙江路 1518 弄",
    };
    return {
      tableData: Array(20).fill(item),
    };
  },
  created() {
    // this.infor = JSON.parse(localStorage.getItem("infor"));
    // console.log(this.infor);
    // if (this.infor.token) {
    //   this.$router.replace("/login");
    // }
  },
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
}

.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}
</style>
