import Vue from 'vue';
import VueRouter from 'vue-router';
import { authCheck } from '../utils/auth-check';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue'),
        children: [
            {
                path: '/statistics',
                component: () => import('../views/StatisticsView.vue'),
            },
            {
                path: '/user',
                component: () => import('../views/UserView.vue'),
            },
            {
                path: '/assistant/:status',
                component: () => import('../views/ProcessAssistantView.vue'),
            },
            {
                path: '/assistant/rank',
                component: () => import('../views/RankView.vue'),
            },
            {
                path: '/coach/:status',
                component: () => import('../views/CoachView.vue'),
            },
            {
                path: '/billi/commission',
                component: () => import('../views/BilliCommissionView.vue'),
            },
            {
                path: '/billi/:status',
                component: () => import('../views/BilliView.vue'),
            },
            {
                path: '/promotion',
                component: () => import('../views/RebateView.vue'),
            },
            {
                path: '/recharge',
                component: () => import('../views/RechargeView.vue'),
            },
            {
                path: '/refund',
                component: () => import('../views/RefundView.vue'),
            },
            {
                path: '/report',
                component: () => import('../views/ReportView.vue'),
            },
            {
                path: '/withdraw',
                component: () => import('../views/DrawalView.vue'),
            },
            {
                path: '/commission',
                component: () => import('../views/CommissionView.vue'),
            },
            {
                path: '/financeorder',
                component: () => import('../views/OrderRebateView.vue'),
            },
            {
                path: '/commissionset',
                component: () => import('../views/PlatformBaseSetup.vue'),
            },
            {
                path: '/BilliCommissionSetup/:id',
                component: () => import('../views/BilliCommissionSetup.vue'),
            },
            {
                path: '/order',
                component: () => import('../views/OrderView.vue'),
            },
            {
                path: '/orderDetail/:id',
                component: () => import('../views/OrderDetailView.vue'),
            },
            {
                path: '/complaint-order',
                component: () => import('../views/OrdercomplaintView.vue'),
            },
            {
                path: '/assistionset',
                component: () => import('../views/AssistantSetView.vue'),
            },
            {
                path: '/travelexpense',
                component: () => import('../views/TravelExpenseConfigView.vue'),
            },
            {
                path: '/coachset',
                component: () => import('../views/CoachSetView.vue'),
            },
            {
                path: '/swiper',
                component: () => import('../views/SwiperView.vue'),
            },
            {
                path: '/swiperadd',
                component: () => import('../views/SwiperSetView.vue'),
            },
            {
                path: '/swiperedit/:id',
                component: () => import('../views/SwiperEditView.vue'),
            },
            {
                path: '/role',
                component: () => import('../views/RoleManageView.vue'),
            },
            {
                path: '/auth',
                component: () => import('../views/AuthView.vue'),
            },
            {
                path: '/admin',
                component: () => import('../views/AdminView.vue'),
                // meta: {
                //     auth: 'admin:view',
                // },
            },
            {
                path: '/notice',
                component: () => import('../views/NoticeView.vue'),
            },
            {
                path: '/notification',
                component: () => import('../views/NotificationView.vue'),
            },
            {
                path: '/addnotice',
                component: () => import('../views/NoticeAddView.vue'),
            },
            {
                path: '/addnotification',
                component: () => import('../views/NotificationAddView.vue'),
            },
            {
                path: '/coupon',
                component: () => import('../views/CouponView.vue'),
            },
            {
                path: '/message',
                component: () => import('../views/MessageView.vue'),
            },
            {
                path: '/group',
                component: () => import('../views/GroupView.vue'),
            },
            {
                path: '/groupmember/:id',
                component: () => import('../views/GroupMemberView.vue'),
            },
            {
                path: '/addcoupon',
                component: () => import('../views/CouponAddView.vue'),
            },
            {
                path: '/question',
                component: () => import('../views/QuestionView.vue'),
            },
            {
                path: '/app',
                component: () => import('../views/AppVersion.vue'),
            },
            {
                path: '/appadd',
                component: () => import('../views/AppVersionAdd.vue'),
            },
            {
                path: '/agent',
                component: () => import('../views/AgentView.vue'),
            },
            {
                path: '/agentManagement',
                component: () => import('../views/AgentManagement.vue'),
            },
            {
                path: '/agentCommission',
                component: () => import('../views/AgentCommission.vue'),
            },
            {
                path: '/agentCommissionSetup/:id',
                component: () => import('../views/AgentCommissionSetup.vue'),
            },
            {
                path: '/agentPriceSetup/:id',
                component: () => import('../views/AgentPriceSetup.vue'),
            },
            {
                path: '/distributioncommission',
                component: () => import('../views/DistributionCommissionView.vue'),
            }
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue'),
    },
    {
        path: '/403',
        name: 'unAuth',
        component: () => import('../views/UnAuthView.vue'),
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // 权限判断
    if (to?.meta?.auth) {
        if (authCheck(to.meta.auth)) {
            next();
        } else {
            next('/403');
        }
    } else {
        next();
    }
});

export default router;
