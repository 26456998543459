import Vue from "vue";
import App from "./App.vue";
import './assets/style.less';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import router from "./router";
import * as echarts from "echarts";
import axios from "axios";
import store from "./store/index";
import VideoPlayer from "vue-video-player";
import 'video.js/dist/video-js.css'
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import { authCheck } from "./utils/auth-check";
import { getLabel } from "./utils/dictHolder";
import { createVuePlugin } from 'thinking-data-provider';

Vue.use(createVuePlugin());

import { getAxios } from './axios'

Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(VideoPlayer);
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;
Vue.prototype.getLabel = getLabel;
// Vue.prototype.weburl = "http://admin.frp.efeichong.com";
Vue.prototype.weburl = "https://admin.yunyiheng.com";
// Vue.prototype.weburl = "https://test-admin.yunyiheng.com";
// Vue.prototype.weburl = "http://192.168.2.102:8086";
// Vue.prototype.weburl = "http://192.168.0.117:8086";

const http = getAxios(Vue.prototype.weburl);
Vue.prototype.$http = http;
Vue.prototype.$authCheck = authCheck;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
