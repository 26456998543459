let dc = {};
function dict(value,label){
    this.value = value;
    this.label = label;
}

const coach_grade = [
    new dict(null,"球搭"), new dict(0,"初级"),new dict(1,"中级"),new dict(2,"高级")
]
const assistant_grade = [
    new dict(0,"一星"),new dict(1,"二星"),new dict(2,"三星"),new dict(3,"四星"),
    new dict(4,"五星"),new dict(5,"六星"),new dict(6,"王牌")
]
const user_type=[
    new dict(1,"教练"),new dict(2,"助教")
]
const order_type=[
    new dict(1,"教练服务"),new dict(2,"助教服务")
]
const pay_type = [
    new dict(0,"余额支付"),new dict(1,"微信APP支付"),new dict(2,"微信公众号支付"),
    new dict(3,"微信小程序支付"),new dict(4,"支付宝支付")
]
const order_status = [
    new dict(0,"待支付"),new dict(1,"已支付"),new dict(2,"已接单"),new dict(3,"已拒绝"),new dict(4,"已到达"),new dict(5,"服务中"),
    new dict(6,"服务完成"),new dict(7,"已评价"),new dict(8,"售后中"),new dict(9,"售后完成"),new dict(10,"已取消")
]
const withdraw_type = [
    new dict(0,"微信"),new dict(1,"支付宝")
]
dc.user_type=user_type;
dc.coach_grade=coach_grade;
dc.assistant_grade=assistant_grade;
dc.pay_type=pay_type;
dc.order_status=order_status;
dc.withdraw_type=withdraw_type;
dc.order_type=order_type;

export const getLabel = (key, value)=>{
    const label = dc[key].filter(d => d.value==value);
    if (label.length>0){
        return label[0].label;
    }
    return value;
};

// module.exports.dc=dc;
export {dc};
